const breakpoints = ['320px', '650px', '1000px', '1400px'];

const mediaQueries = {
  sm: `@media screen and (min-width: ${breakpoints[0]}) and (max-width: ${breakpoints[1]})`,
  md: `@media screen and (min-width: ${breakpoints[1]}) and (max-width: ${breakpoints[2]})`,
  lg: `@media screen and (min-width: ${breakpoints[2]}) and (max-width: ${breakpoints[3]})`,
  xl: `@media screen and (min-width: ${breakpoints[3]})`,
};

const devices = {
  mobile: mediaQueries.sm,
  desktop: `@media screen and (min-width: ${breakpoints[1]})`,
};

const theme = {
  breakpoints,
  mediaQueries,
  fontSizes: ['1rem', '1.2rem', '1.44rem', '1.728rem', '2.074rem', '2.488rem'],
  colors: {
    primary: '#c66131',
    secondary: '#494992',
    grey: '#58545a',
    shade: '#f5f5f5',
    background: '#333333',
  },
  devices,
  space: [
    '0',
    '0.25rem',
    '0.5rem',
    '0.75rem',
    '1rem',
    '1.25rem',
    '1.5rem',
    '2rem',
    '2.5rem',
    '3rem',
    '4rem',
    '6rem',
    '8rem',
    '12rem',
    '16rem',
  ],
  fontWeights: {
    normal: 400,
    bold: 700,
  },
  sidebarWidth: {
    big: '375px',
    normal: '320px',
  },
};

export default theme;
