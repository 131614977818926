import { Link } from 'gatsby';
import React, { useState } from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import { flexbox, layout, space } from 'styled-system';
import { Flex, Button } from '../elements';
import GlobalStyles from '../styles/globalTheme';
import theme from '../styles/theme';
import { sideBarWidth } from '../utils/sizes';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';
import Sidebar from './SideNav';

const NavLg = styled.nav`
  box-sizing: border-box;
  ${layout}
  ${space}
`;

const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 10px;
  ${flexbox};
  position: relative;
`;

const Li = styled.li`
  box-sizing: border-box;
  padding: 12px 0;
  margin: 0 10px;
`;

const PageWrapper = styled(Flex)`
  ${props =>
    props.toggleSideNav
      ? css`
          transform: translateX(${sideBarWidth});
        `
      : css`
          transform: translateX(0);
        `}
  transition: transform 600ms ease-in-out;
`;

const Main = styled.main`
  flex-grow: 1;
  ${space}
`;

const CommonLayout = ({
  header = true,
  location,
  children,
  data,
  allUpperProjects,
  allUpperDomains,
  breadcrumb,
  ...main
}) => {
  const [toggleSideNav, setToggleSideNav] = useState(false);
  let fullPathLevel1;

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        {data ? (
          <>
            <Sidebar toggleSideNav={toggleSideNav}>
              <Button
                css={{ position: 'absolute', top: '15px', right: '15px' }}
                onClick={() => setToggleSideNav(!toggleSideNav)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path fill="none" d="M0 0h24v24H0V0z" />
                  <path
                    fill="black"
                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
                  />
                </svg>
              </Button>
              <NavLg mt="30px">
                <Ul>
                  {data.prismicMenu.data.nav.map(navItem => {
                    if (navItem.primary.link.document.uid) {
                      fullPathLevel1 = `/${data.prismicMenu.data.prefix}/${navItem.primary.link.document.uid}`;
                    } else {
                      fullPathLevel1 = `/${data.prismicMenu.data.prefix}`;
                    }

                    return (
                      <Li key={navItem.primary.link.document.uid}>
                        <Link to={fullPathLevel1}>{navItem.primary.label.text}</Link>
                      </Li>
                    );
                  })}
                </Ul>
                {location.pathname.includes('/art') && (
                  <Ul>
                    <Li>
                      <Link to="/art">Artworks</Link>
                    </Li>
                  </Ul>
                )}
                <Ul>
                  {allUpperDomains &&
                    allUpperDomains.map(domain => {
                      return (
                        <Li key={domain.path}>
                          <Link to={domain.path}>{domain.title.text}</Link>
                        </Li>
                      );
                    })}
                </Ul>
              </NavLg>
            </Sidebar>
            <PageWrapper toggleSideNav={toggleSideNav} height="100vh" flexDirection="column">
              {header && (
                <>
                  <DesktopHeader
                    menu={data.prismicMenu}
                    display={['none', 'none', 'none', 'flex']}
                    location={location}
                    breadcrumb={breadcrumb}
                  />
                </>
              )}
              <MobileHeader
                setToggleSideNav={setToggleSideNav}
                toggleSideNav={toggleSideNav}
                location={location}
                menu={data.prismicMenu}
                display={['flex', 'flex', 'flex', 'none']}
              />
              <Main {...main}>{children}</Main>
            </PageWrapper>
          </>
        ) : (
          <Flex height="100vh" flexDirection="column">
            <Main {...main}>{children}</Main>
          </Flex>
        )}
      </>
    </ThemeProvider>
  );
};

export default CommonLayout;
