import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import CommonLayout from './CommonLayout';

const designQuery = graphql`
  query DesignMenuQuery {
    prismicMenu(uid: {eq: "design-menu"}, lang: {eq: "en-us"}) {
      data {
        display_name {
          text
        }
        prefix
        nav {
          ... on PrismicMenuNavNavItem {
            id
            primary {
              label {
                text
              }
              link {
                document {
                  __typename
                  ... on PrismicPage {
                    uid
                  }
                  ... on PrismicClientList {
                    uid
                  }
                }
              }
            }
            items {
              sub_nav_link {
                document {
                  __typename
                  ... on PrismicPage {
                    uid
                  }
                  ... on PrismicClientList {
                    uid
                  }
                }
              }
              sub_nav_link_label {
                text
              }
            }
          }
        }
      }
    }
  }
`;

const Design = ({ mt, ...props }) => {
  return (
    <StaticQuery
      query={designQuery}
      render={data => <CommonLayout mt={mt} data={data} {...props} />}
    />
  );
};

export default Design;
