import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { SearchIcon, Input } from './styles';

export default connectSearchBox(({ refine,currentRefinement,  ...rest }) => (
  <form>
    <Input
      type="text"
      placeholder="Search"
      aria-label="Search"
      value={currentRefinement}
      //onFocus={onFocus}
      onChange={e => refine(e.target.value)}      
      {...rest}
    />
    <SearchIcon />
  </form>
));
