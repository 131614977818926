import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { flexbox, layout } from 'styled-system';
import BreadCrumb from './BreadCrumb';
import Search from './search';
import { Flex } from '../elements';

const Header = styled.header`
  display: ${props => (props.header ? 'flex' : 'none')};
  height: 60px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid #9f9f9f;
  flex-shrink: 0;

  ${layout}
`;

const NavLg = styled.nav`
  box-sizing: border-box;
  ${layout}
`;

const Ul = styled.ul`
  display: flex;
  box-sizing: border-box;
  align-items: baseline;
  padding: 10px 0 10px 10px;
  ${flexbox};
  position: relative;

  max-height: 100px;
  & > *:not(:last-child)::after {
    content: '|';
    margin: 0 10px;
    font-size: 20px;
    position: relative;
    top: 2px;
  }
`;

const UlSubItem = styled(Ul)`
  display: none;
  position: absolute;
  right: -10px;
  top: 40px;
  &:hover {
    display: flex;
  }
`;

const Li = styled.li`
  box-sizing: border-box;
  text-align: center;
  position: relative;
  white-space: nowrap;
  z-index: 10;
  &:hover {
    ${UlSubItem} {
      display: flex;
      background-color: #333333;
      padding-right: 5px;
    }
  }
`;

const DesktopHeader = ({ location, header, menu, breadcrumb, ...rest }) => {
  const fullPathLevel0 = menu.data.prefix;
  let fullPathLevel1;
  let fullPathLevel2;
  let crumbs;
  if (breadcrumb) {
    // eslint-disable-next-line prefer-destructuring
    crumbs = breadcrumb.crumbs;
  }
  const searchIndices = [
    { name: process.env.GATSBY_ALGOLIA_INDEX_NAME, title: `pages`, hitComp: `PageHit` },
  ];
  return (
    <Header header={header} {...rest}>
      {crumbs && <BreadCrumb crumbSeparator={' > '} crumbs={crumbs} />}
      <Flex alignItems="center" >
        <NavLg>
          <Ul>
            {menu.data.nav.map(navItem => {
              if (navItem.primary.link) {
                fullPathLevel1 = `/${fullPathLevel0}/${navItem.primary.link.document.uid}`;
              } else {
                fullPathLevel1 = `/${fullPathLevel0}`;
              }
              //
              return (
                <Li key={navItem.primary.link.document.uid}>
                  <Link css="padding-bottom: 20px" to={fullPathLevel1}>
                    {navItem.primary.label.text}
                  </Link>

                  {navItem.items.length > 0 && (
                    <UlSubItem key={navItem.items}>
                      {navItem.items.map(navSubItem => {
                        if (navSubItem.sub_nav_link.document.uid) {
                          fullPathLevel2 = `${fullPathLevel1}/${navSubItem.sub_nav_link.document.uid}`;
                        } else {
                          fullPathLevel2 = `${fullPathLevel1}`;
                        }
                        return (
                          <Li key={navSubItem.sub_nav_link.document.uid}>
                            <Link to={fullPathLevel2}>{navSubItem.sub_nav_link_label.text}</Link>
                          </Li>
                        );
                      })}
                    </UlSubItem>
                  )}
                </Li>
              );
            })}
          </Ul>
        </NavLg>
        <Search indices={searchIndices} />
      </Flex>
    </Header>
  );
};

export default DesktopHeader;
