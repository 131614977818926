import styled, { css } from 'styled-components';
import { sideBarWidth } from '../utils/sizes';

const Sidebar = styled.nav`
  position: fixed;
  transform: translateZ(0);
  display: block;
  z-index: 1;
  left: 0;
  top: 0;
  width: ${sideBarWidth};
  height: 100%;
  /* box-sizing: border-box; */
  color: black;
  background: white;
  overflow-y: auto;
  transition: transform 600ms ease-in-out;
  ${p =>
    p.toggleSideNav
      ? ``
      : css`
          transform: translateX(-101%);
        `}
`;

export default Sidebar;
