module.exports = {
    pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
    title: 'Arik Levy Art and Design Studio', // Navigation and Site Title
    titleAlt: 'Arik Levy', // Title for JSONLD
    description: 'Arik Levy Studio presents .',
    headline: 'Arik Levy art pieces', // Headline for schema.org JSONLD
    url: process.env.GATSBY_SITE_URL, // Domain of your site. No trailing slash!
    siteLanguage: 'en', // Language Tag on <html> element
    logo: '/images/AL_favicon.png', // Used for SEO
    ogLanguage: 'en_US', // Facebook Language
  
    // JSONLD / Manifest
    favicon: 'src/static/images/AL_favicon.png', // Used for manifest favicon generation
    shortName: 'ArikLevy', // shortname for manifest. MUST be shorter than 12 characters
    author: 'Isayas', // Author for schemaORGJSONLD
    themeColor: '#3D63AE',
    backgroundColor: '#EBEDF2',
  
    twitter: '@Arik_Levy', // Twitter Username
    facebook: 'ariklevydesign', // Facebook Site Name
    googleAnalyticsID: 'UA-717493-15',
  
    skipNavId: 'reach-skip-nav', // ID for the "Skip to content" a11y feature
  };
  