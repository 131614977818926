import PropTypes from 'prop-types';
import React from 'react';
import Art from './Art';
import CommonLayout from './CommonLayout';
import Design from './Design';

const Layout = ({ children, header = true, location, mt, ...rest }) => {
  if (header) {
    if (location) {
      const arrPath = location.pathname.split('/');

      if (arrPath[1] === 'art') {
        return (
          <Art mt={mt} header={header} location={location} {...rest}>
            {children}
          </Art>
        );
      }

      if (arrPath[1] === 'design') {
        return (
          <Design mt={mt} header={header} location={location} {...rest}>
            {children}
          </Design>
        );
      }
    } else {
      return <CommonLayout {...rest}>{children}</CommonLayout>;
    }
  } else {
    return <CommonLayout {...rest}>{children}</CommonLayout>;
  }
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
