import { createGlobalStyle } from 'styled-components';
import reset from './reset';
import theme from './theme';

const GlobalStyles = createGlobalStyle`
@font-face {
  font-family: "Arial-Black";
  src: url("/fonts/arial-blackfrallfontnet.eot"); /* IE9 Compat Modes */
  src:
    url("/fonts/arial-blackfrallfontnet.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("/fonts/arial-blackfrallfontnet.woff2") format("woff2"),
    url("/fonts/arial-blackfrallfontnet.woff") format("woff"),
    url("/fonts/arial-blackfrallfontnet.ttf") format("truetype"),
    url("/fonts/arial-blackfrallfontnet.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
  *::before,
  *::after {
    box-sizing: border-box;
  }
  ::selection {
    color: white;
    background-color: #f6993f;
  }
  html {
    box-sizing: border-box;
    border: 0;
    margin: 0;


    iframe body.vp-center {
      align-items: start;
    }

    h1, h2, h3, h4, h5, h6 {
      font-weight: ${theme.fontWeights.bold};
    }

    ${theme.devices.desktop} {
    h1 {
      font-size: ${theme.fontSizes[2]};
    }
    h2 {
      font-size: ${theme.fontSizes[4]};
    }
    h3 {
      font-size: ${theme.fontSizes[3]};
    }
    h4 {
      font-size: ${theme.fontSizes[0]};
    }
    h5 {
      font-size: ${theme.fontSizes[1]};
    }
    h6 {
      font-size: ${theme.fontSizes[0]};
    }
    }


    ${theme.devices.mobile} {
        h1 {
          font-size: ${theme.fontSizes[1]};
        }
        h2 {
          font-size: ${theme.fontSizes[0]};
        }
        h3 {
          font-size: ${theme.fontSizes[0]};
        }
        h4 {
          font-size: ${theme.fontSizes[0]};
        }
        h5 {
          font-size: ${theme.fontSizes[0]};
        }
        h6 {
          font-size: ${theme.fontSizes[0]};
        }
    }



  }
  body {
    border: 0;
    margin: 0 auto;
    padding: 0;
    height:100%;
    width:90%;
    color: white;
    /* font-family: 'Work Sans', '-apple-system', 'Roboto', 'Helvetica', 'Arial', sans-serif; */
    font-family: 'Arial', 'Arial-Black', sans-serif;
    background: ${theme.colors.background};
    font-size: 14px;
    ${theme.mediaQueries.sm} {
        width:100%;
      }
  }
  a {
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    color: inherit;
    &:hover,
    &:focus {
      color: ${theme.colors.primary};
    }
  }

  ${reset}
`;

export default GlobalStyles;
