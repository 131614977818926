import React from 'react';
import { Link } from 'gatsby';

export const PageHit = clickHandler => ({ hit }) => {
  //console.log('PageHit hit->', hit)
  let context;
  if (hit.context) {
    context = hit.context;
  } else {
    context = 'no context';
  }
  return (
    <div>
      <Link to={hit.path} onClick={clickHandler}>
        <h4>{context}</h4>
        {hit.secondaryContext && <p css="font-size: 11px; color:grey;">{hit.secondaryContext} </p>}
      </Link>
    </div>
  );
};
